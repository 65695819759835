




























































import { Component, Prop, Vue } from "vue-property-decorator";
import { IStrDetailValue, IEmail } from "@/Services/Interfaces";
import { globalUpdateDetailService } from "@/Services/GlobalService";
import { loginViewModel } from "@/Services/LoginViewModel";
import { CrudType } from "@/Services/enums";
import BaseSubComponent from "./BaseSubComponent.vue";
import ActionComponent from "./ActionComponent.vue";
import store from "@/store";

@Component({ components: { ActionComponent, BaseSubComponent } })
export default class EmailComponent extends BaseSubComponent {
  emails: IEmail[];
  Id: number;
  constructor() {
    super();
    this.name = "EmailComponent";

    this.thismodel = <IStrDetailValue>{};
    this.selectedemail = {};
    this.Id = new Date().getTime();
    this.emails = new Array<IEmail>();
  }

/**Email darf eeditiert werden */
CanEmailEdit(model: IStrDetailValue) : boolean
{
  return true;
  if (model == undefined) return false;
  if (model.typname.toLowerCase() == "whatsup")
  {
      if (model.crud == "create") return true;
      return this.emails.filter(t=> t.email == model.strvalue).length > 0;
  }
  var result =  model.crud != "noedit"  ;
  if (!result)
  {
      return this.emails.filter(t=> t.email == model.strvalue).length > 0;
  }
  return result;
}



  getHint(e: IEmail): string {
    if (!this.emails)  return "";
    var email = e.email == undefined ? <any>e : e.email;
    var filtertxte = this.emails.filter(t => t.email == email);
    if (filtertxte.length > 0) return filtertxte[0].name;
    return e.email == undefined ? <any>e : e.name;
  }

  changed(e: IEmail): void {
    this.thismodel.strvalue = e.email == undefined ? <any>e : e.email;
    this.onUpdate(this.thismodel);
    if (this.thismodel.id && this.thismodel.id > 0)
    {
      loginViewModel.getEmails().then(e => {
        this.emails = e;
      });
    }
  }

  thismodel: IStrDetailValue;
  name: string;
  selectedemail: any;

  

  data() {
    return {
      thismodel: this.model,
      emails: store.getters.emails,
      selectedemail: (this.model ?  this.model.strvalue : ''),
      search: null,
      canAction: false
    };
  }

  onUpdate(model: IStrDetailValue): void {
    if (this.canUpdate(model)) {
      model.crud = CrudType.updatestrvalue;
      globalUpdateDetailService.sendMessage(model);
      this.selectedemail = model.strvalue;
    }
  }

  mounted() {
    try {
      loginViewModel.getEmails().then(e => {
        this.emails = e;
      });
    } catch (e) {
      console.debug(e);
    }
  }
}
